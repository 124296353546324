import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

const TeamPage = () => {
  return (
    <Layout pageKey="blog">
      <Seo title={"Blog"} />
    </Layout>
  )
}

export default TeamPage
